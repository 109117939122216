<template>
  <TianyaBaseComponent :source="1" />
</template>

<script>
import TianyaBaseComponent from './TianyaBaseComponent.vue';

export default {
  name: 'TianyaZhuti',
  components: {
    TianyaBaseComponent,
  },
};
</script>

<style scoped>
/* 如果有需要，可以添加特定于 TianyaZhuti 的样式 */
</style>