<template>
  <div class="header-content">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <el-menu-item index="0" @click="navigateTo('/')">
        <img
          style="width: 50px"
          src="/images/logo.png"
          alt="Element logo"
        />
      </el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="1" @click="navigateTo('/')">Home</el-menu-item>
      <el-sub-menu index="2">
        <template #title>YM</template>
        <el-menu-item index="2-1" @click="navigateTo('/tianyazhuti')">天涯主贴</el-menu-item>
        <el-menu-item index="2-2" @click="navigateTo('/tianyabuluo')">天涯部落</el-menu-item>
        <el-menu-item index="2-3" @click="navigateTo('/wangyiyun')">网易云</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="3">
        <template #title>
          <el-icon>
            <div class="user-icon">
              <el-avatar :icon="UserFilled" />
            </div>
          </el-icon>
        </template>
        <el-menu-item index="3-1">用户信息</el-menu-item>
        <el-menu-item index="3-2">个性设置</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'HeaderComponent',
  setup() {
    const activeIndex = ref('1')
    const router = useRouter()
    const route = useRoute()

    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath)
    }

    const navigateTo = (path) => {
      router.push(path)
    }

    const updateActiveIndex = () => {
      const path = route.path
      switch (path) {
        case '/':
          activeIndex.value = '1'
          break
        case '/tianyazhuti':
          activeIndex.value = '2-1'
          break
        case '/tianyabuluo':
          activeIndex.value = '2-2'
          break
        case '/wangyiyun':
          activeIndex.value = '2-3'
          break
        default:
          activeIndex.value = '1'
      }
    }

    onMounted(() => {
      updateActiveIndex()
    })

    watch(route, () => {
      updateActiveIndex()
    })

    return {
      activeIndex,
      handleSelect,
      navigateTo
    }
  }
}
</script>

<style scoped>
.header-content {
  width: 100%;
}

.flex-grow {
  flex-grow: 1;
}

.user-icon > div:not(:last-child) {
  border-right: 1px solid var(--el-border-color);
}
</style>