// CardData.js
export const CardData = {
    1: [
      {
        title: '发帖 Top10',
        items: [
          { name: '琴间的律动', value: 60294 },
          { name: '一个人一世界80', value: 12636 },
          { name: '世界很大阿', value: 9022 },
          { name: '新型关系', value: 6900 },
          { name: 'Jessiehw2015', value: 6896 },
          { name: '让人想着很幸福', value: 6863 },
          { name: '斐查兹', value: 6441 },
          { name: '縌i鱗', value: 4774 },
          { name: '欣赏美好K', value: 4611 },
          { name: '2016平淡如水', value: 4247 },
        ],
      },
      {
        title: '评论Top 10',
        items: [
          { name: '吾是小米_H', value: 61226 },
          { name: '一个人一世界80', value: 52372 },
          { name: 'Jessiehw2015', value: 45074 },
          { name: '欣赏美好K', value: 38721 },
          { name: '玲儿治治', value: 35490 },
          { name: 'pipilwt', value: 28974 },
          { name: '风中雨荷2018', value: 28812 },
          { name: '骁肖', value: 25991 },
          { name: '西子人家A', value: 25691 },
          { name: 'WM好运', value: 23719 },
        ],
      },
    ],
    2: [
      {
        title: '发帖Top 10',
        items: [
          { name: '世界很大阿', value: 12583 },
          { name: 'L我曾用心爱着', value: 10209 },
          { name: '琴间的律动', value: 6735 },
          { name: '一个人一世界80', value: 4627 },
          { name: '玲儿治治', value: 4460 },
          { name: 'Jessiehw2015', value: 3312 },
          { name: '微微温情搁浅', value: 2827 },
          { name: '欣赏美好K', value: 2450 },
          { name: '云上的日子Y', value: 2305 },
          { name: '外白渡桥的牛0825', value: 2244 },
        ],
      },
      {
        title: '评论Top 10',
        items: [
          { name: '玲儿治治', value: 21637 },
          { name: '陷在温柔乡', value: 17500 },
          { name: '外白渡桥的牛', value: 17416 },
          { name: 'Jessiehw2015', value: 16105 },
          { name: '微微温情搁浅', value: 15763 },
          { name: '带有余温的过客', value: 12337 },
          { name: '世界很大阿', value: 11739 },
          { name: '一个人一世界80', value: 10662 },
          { name: '让人想着很幸福', value: 10605 },
          { name: '爱末的首席漂亮官', value: 9839 },
        ],
      },
    ],
  };