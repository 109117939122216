<template>
    <div class="pagination-container">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="handlePageChange"
      />
      <el-select :model-value="pageSize" @update:model-value="updatePageSize" class="page-size-select">
        <el-option
          v-for="size in pageSizes"
          :key="size"
          :label="`${size} 条/页`"
          :value="size"
        />
      </el-select>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PaginationComponent',
    props: {
      total: {
        type: Number,
        required: true,
      },
      initialPageSize: {
        type: Number,
        default: 10,
      },
      initialPageSizes: {
        type: Array,
        default: () => [10, 20, 50, 100],
      },
      currentPage: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        pageSize: this.initialPageSize,
        pageSizes: this.initialPageSizes,
      };
    },
    methods: {
      handlePageChange(page) {
        this.$emit('page-change', page);
      },
      updatePageSize(size) {
        this.pageSize = size;
        this.$emit('update:pageSize', size);
        this.handleSizeChange(size);
      },
      handleSizeChange(size) {
        this.$emit('size-change', size);
      },
    },
  };
  </script>
  
  <style scoped>
  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .page-size-select {
    margin-left: 10px;
    width: 120px;
  }
  </style>