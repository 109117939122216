import { createRouter, createWebHistory } from 'vue-router'
import ContentComponent from '../components/ContentComponent.vue'
import TianyaZhutiComponent from '../components/TianyaZhutiComponent.vue'
import TianyaBuluoComponent from '../components/TianyaBuluoComponent.vue'
import WangyiyunComponent from '../components/WangyiyunComponent.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ContentComponent
  },
  {
    path: '/tianyazhuti',
    name: 'TianyaZhuti',
    component: TianyaZhutiComponent
  },
  {
    path: '/tianyabuluo',
    name: 'TianyaBuluo',
    component: TianyaBuluoComponent
  },
  {
    path: '/wangyiyun',
    name: 'Wanyiyun',
    component: WangyiyunComponent
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
