<template>
  <el-card shadow="hover" v-if="weatherData">
    <template #header>
      <div class="header-content">
        <el-date-picker
          v-model="selectedDate"
          type="date"
          placeholder="选择日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :default-value="defaultDate"
          @change="handleDateChange"
        />
        <el-select v-model="selectedLocation" placeholder="选择地理位置" @change="handleLocationChange" class="location-select">
          <el-option
            v-for="location in locations"
            :key="location.value"
            :label="location.label"
            :value="location.value"
          />
        </el-select>
      </div>
    </template>
    <div class="weather-section">
      <div class="weather-item">
        <el-icon class="icon">
          <Sunrise />
        </el-icon>
        <span class="weather-info">{{ weatherData.hefeng.weather.sunrise }}</span>
        <el-icon class="icon" style="margin-left: 20px;">
          <Sunset />
        </el-icon>
        <span class="weather-info">{{ weatherData.hefeng.weather.sunset }}</span>
      </div>
      <div class="weather-item">
        <span class="weather-info">白天: {{ weatherData.hefeng.weather.textDay }}</span>
        <span class="weather-info" style="margin-left: 20px;">夜间: {{ weatherData.hefeng.weather.textNight }}</span>
      </div>
      <div class="weather-item">
        <span class="weather-info">最高温度: {{ weatherData.hefeng.weather.tempMax }}°C</span>
        <span class="weather-info" style="margin-left: 20px;">最低温度: {{ weatherData.hefeng.weather.tempMin }}°C</span>
      </div>
      <div class="weather-item">
        <span class="weather-info">白天风速: {{ weatherData.hefeng.weather.windSpeedDay }} km/h ({{ weatherData.hefeng.weather.windDirDay }})</span>
        <span class="weather-info" style="margin-left: 5px;">夜晚风速: {{ weatherData.hefeng.weather.windSpeedNight }} km/h ({{ weatherData.hefeng.weather.windDirNight }})</span>
      </div>
      <div class="weather-item">
        <span class="weather-info">湿度: {{ weatherData.hefeng.weather.humidity }}%</span>
      </div>
      <div class="weather-item">
        <span class="weather-info">气压: {{ weatherData.hefeng.weather.pressure }} hPa</span>
      </div>
    </div>
    <div class="air-quality-section">
      <div class="air-quality-item">
        <span class="weather-info">空气质量指数: {{ weatherData.hefeng.air.aqi }}</span>
        <span class="weather-info" style="margin-left: 20px;">类别: {{ weatherData.hefeng.air.category }}</span>
               <span class="weather-info" style="margin-left: 20px;">主要污染物: {{ weatherData.hefeng.air.primary }}</span>
     </div>
   </div>
 </el-card>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { Sunrise, Sunset } from '@element-plus/icons-vue';
import { locations } from './js/MockData'; // 引入 mock 数据

export default {
 name: 'WeatherCard',
 components: {
   Sunrise,
   Sunset,
 },
 data() {
  return {
    weatherData: null,
    selectedDate: dayjs().format('YYYY-MM-DD'), // 默认选中今天的日期
    defaultDate: dayjs().toDate(), // 默认日期对象
    selectedLocation: this.getDefaultLocation(), // 根据 isDefault 字段设置默认选中的地理位置
    locations: locations || [], // 确保 locations 不为 undefined
  };
},
 mounted() {
  this.selectedLocation = this.getDefaultLocation();
   this.fetchWeatherData();
 },
 methods: {
   handleDateChange(value) {
     this.selectedDate = dayjs(value).format('YYYY-MM-DD');
     this.fetchWeatherData();
   },
   handleLocationChange(value) {
     this.selectedLocation = value;
     this.fetchWeatherData();
   },
   async fetchWeatherData() {
     try {
       const response = await axios.get(`https://step.feiyu.site/weather?date=${this.selectedDate}&location=${this.selectedLocation}`);
       this.weatherData = response.data;
     } catch (error) {
       console.error('Error fetching weather data:', error);
     }
   },
   getDefaultLocation() {
     if (!this.locations || this.locations.length === 0) {
       return null;
     }
     const defaultLocation = this.locations.find(location => location.isDefault);
     return defaultLocation ? defaultLocation.value : this.locations[0].value;
   },
 },
};
</script>

<style scoped>
.header-content {
 display: flex;
 align-items: center;
}
.icon {
 margin-right: 5px;
}
.header-text {
 margin-right: 10px;
 color: #888;
}
.weather-section, .air-quality-section {
 margin-top: 10px;
}
.weather-item, .air-quality-item {
 display: flex;
 align-items: center;
 margin-bottom: 10px;
}
.location-select {
 width: 40%;
 padding-left: 5%;
}
</style>
        