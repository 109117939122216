<template>
  <el-container style="min-height: 100vh;">
    <el-header style="padding: 0;">
      <HeaderComponent />
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer style="padding: 0;">
      <FooterComponent />
    </el-footer>
  </el-container>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
}

.el-header, .el-footer {
  color: #fff; /* 确保文字颜色与背景色对比明显 */
  text-align: center;
}

.el-main {
  background-color: #E9EEF3;
  flex: 1;
}
</style>
