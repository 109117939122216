<template>
     <div class="footer-content">
       STEP-SYSTEM FROM 2024 
     </div>
   </template>
   
   <script>
   export default {
     name: 'FooterComponent'
   }
   </script>
   // 设置字体颜色为黑色
   <style scoped>
   .footer-content {
     text-align: center;
     font-size: 14px;
     padding: 20px;
     color: black;

   }
   </style>
   