<template>
  <div class="content">
    <div class="card-container">
      <el-card style="max-width: 450px" shadow="hover">
        <template #header>六年楼</template>
        <div v-for="(item, index) in items" :key="index" class="card-item">
          <router-link :to="item.route">
            {{ item.name }}
          </router-link>
        </div>
        <img
          src="https://img.feiyu.site/api/v1/buckets/wangyiyun/objects/download?preview=true&version_id=null&prefix=img/312374163_24064813290_1.jpg"
          style="width: 40%"
        />
      </el-card>
      <weather-card :date="weatherDate" style="max-width: 450px" />
    </div>
  </div>
</template>

<script>
import WeatherCard from './WeatherCard.vue';

export default {
  name: 'ContentComponent',
  components: {
    WeatherCard,
  },
  data() {
    return {
      weatherDate: this.getCurrentDate(), // 动态设置当前日期
      items: [
        { name: '天涯主贴', route: '/tianyazhuti' },
        { name: '天涯部落', route: '/tianyaBuluo' },
        { name: '网易云', route: '/wangyiyun' }
      ]
    };
  },
  methods: {
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要+1
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style scoped>
.content {
  text-align: center;
  font-size: 16px;
}
.card-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px; /* 卡片之间的间距 */
  min-width: 500px; /* 设置最小宽度 */
}
.card-item {
  text-align: left; /* 确保链接文本左对齐 */
  margin-bottom: 10px; /* 可选：增加一些间距 */
  margin-top: 10px;
  margin-left: 10px;
}

</style>