<template>
  <TianyaBaseComponent :source="3" />
</template>

<script>
import TianyaBaseComponent from './TianyaBaseComponent.vue';

export default {
  name: 'Wangyiyun',
  components: {
    TianyaBaseComponent,
  },
};
</script>

<style scoped>
/* 这里可以添加新组件的样式 */
</style>