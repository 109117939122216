export const locations = [
    { value: '101010100', label: '北京', isDefault: false },
    { value: '101010200', label: '北京海淀', isDefault: false },
    { value: '101010300', label: '北京朝阳', isDefault: true },
    { value: '101120602', label: '青州', isDefault: false },
    { value: '101250510', label: '永兴', isDefault: false },
    { value: '101290101', label: '昆明', isDefault: false },
    { value: '101290201', label: '大理', isDefault: false },
    { value: '101291401', label: '丽江', isDefault: false },
    { value: '101270101', label: '成都', isDefault: false },
    { value: '101040100', label: '重庆', isDefault: false },
    { value: '101130101', label: '乌鲁木齐', isDefault: false },
    { value: '101140101', label: '拉萨', isDefault: false },
    { value: '101190501', label: '南通市', isDefault: false },
    { value: '101120201', label: '青岛', isDefault: false },
    { value: '101280101', label: '广州', isDefault: false }, // 气候宜人，美食丰富
    { value: '101210101', label: '杭州', isDefault: false }, // 气候宜人，美食丰富
    { value: '101230201', label: '厦门', isDefault: false }, // 气候宜人，美食丰富
    { value: '101200101', label: '武汉', isDefault: false }, // 美食丰富
    { value: '101110101', label: '西安', isDefault: false }, // 美食丰富
    { value: '101020100', label: '上海', isDefault: false }, // 气候宜人，美食丰富
    { value: '101070101', label: '沈阳', isDefault: false }, // 美食丰富
    { value: '101050101', label: '哈尔滨', isDefault: false }, // 气候宜人
    { value: '101100101', label: '太原', isDefault: false }, // 美食丰富
    { value: '101160101', label: '兰州', isDefault: false }, // 美食丰富
];
