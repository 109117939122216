<template>
  <div class="container">
    <div class="card-list">
      <el-card
        v-for="item in paginatedData"
        :key="item.id"
        shadow="hover"
      >
        <template #header>
          <div class="header-content">
            <el-icon class="icon" v-if="source !== 3">
              <Document />
            </el-icon>
            <span class="header-text" v-if="source !== 3">楼层：{{ item.floor }}</span>
            <el-icon class="icon">
              <User />
            </el-icon>
            <span class="header-text">{{ item.author }}</span>
            <el-icon class="icon">
              <Clock />
            </el-icon>
            <span class="header-text">{{ source === 3 ? item.date : formatDate(item.issue_time) }}</span>
          </div>
        </template>
        <div v-if="item.images && item.images.length" class="image-container">
          <img v-for="(image, index) in item.images" :key="index" :src="image" class="image" />
        </div>
        <div v-if="source == 3 && item.music"> {{ item.music.songUserName }} : {{ item.music.songName }}</div>
        <div>{{ item.content }}</div>
        <div class="comment-button-container">
          <el-button
            :disabled="!item.comments || item.comments.length === 0"
            @click="toggleComments(item)"
          >
            {{ item.comments ? `查看评论 (${item.comments.length})` : '无评论' }}
          </el-button>
        </div>
        <div v-if="item.showComments" class="comments-container">
          <div v-for="comment in item.comments" :key="comment.id" class="comment-item">
            <p>{{ source === 3 ? '' : comment.comment_name }}{{ source === 3 ? '' : ':'}} {{  source === 3 ? comment.comment : comment.content }}</p>
          </div>
        </div>
      </el-card>
      <PaginationComponent
        :total="total"
        :initial-page-size="pageSize"
        :initial-page-sizes="pageSizes"
        :current-page="currentPage"
        @page-change="handlePageChange"
        @size-change="handleSizeChange"
        @update:pageSize="updatePageSize"
      />
    </div>
    <div class="sidebar">
      <div class="search-container">
        <el-input
          v-model="searchKeyword"
          placeholder="请输入关键词搜索"
          clearable
          @clear="handleSearch"
          @keyup.enter="handleSearch"
        >
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
      </div>
      <div class="button-group">
        <el-button
          v-for="tag in tags"
          :key="tag.display"
          :class="{ active: activeTag === tag.display }"
          @click="handleTagClick(tag)"
        >
          {{ tag.display }}
        </el-button>
      </div>
      <div v-for="card in cardData" :key="card.title" class="stats-card">
        <el-card style="max-width: 300px; margin-bottom: 20px" shadow="hover">
          <template #header>{{ card.title }}</template>
          <div class="card-item" v-for="item in card.items" :key="item.name">
            {{ item.name }}（{{ item.value }}）
          </div>
        </el-card>
      </div>
    </div>
    <div class="scroll-to-top" @click="scrollToTop" :class="{ disabled: isAtTop }">
      <el-icon>
        <ArrowUp />
      </el-icon>
    </div>
    <div class="scroll-to-bottom" @click="scrollToBottom" :class="{ disabled: isAtBottom }">
      <el-icon>
        <ArrowDown />
      </el-icon>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { format } from 'date-fns';
import { Document, User, Clock, ArrowUp, ArrowDown, Search } from '@element-plus/icons-vue';
import PaginationComponent from './PaginationComponent.vue';
import { CardData } from './js/CardData.js'; // 确保路径和文件名一致

export default {
  name: 'TianyaBaseComponent',
  components: {
    Document,
    User,
    Clock,
    ArrowUp,
    ArrowDown,
    Search,
    PaginationComponent,
  },
  props: {
    source: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      paginatedData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      isAtTop: true,
      isAtBottom: false,
      pageSizes: [10, 20, 50, 100],
      searchKeyword: '',
      tagText: '全部',
      activeTag: '全部',
      tags: [
        { display: '全部', value: '全部' },
        { display: '楼', value: '楼主' },
        { display: '更', value: '更文' },
        { display: '爆', value: '爆米花' },
        { display: 'TIP', value: 'TIP' },
      ],
      cardData: [], // 新增，用于存储卡片数据
    };
  },
  methods: {
    async fetchData(pageNo, pageSize, keyWord = '', tagText = '全部') {
      try {
        console.log('source:' + this.source)
        const url = this.source === 3 ? 'https://step.feiyu.site/wangyicloud/queryDynamicList' : 'https://step.feiyu.site/six/page/ty1';
        const response = await axios.post(url, {
          pageNo,
          pageSize,
          source: this.source,
          tagText,
          keyWord
        });
        const data = response.data.data;
        this.paginatedData = data.records.map(record => ({
          ...record,
          showComments: false,
        }));
        this.total = data.total;
        this.scrollToTop(); // 请求数据后滚动到顶部
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    
    async fetchCardData() {
      if (this.source === 3) {
        try {
          const response = await axios.get('https://step.feiyu.site/wangyiyun/info');
          const top10CommentUser = response.data.top_10_comment_user;
          this.cardData = [
            {
              title: '评论Top 10',
              items: Object.keys(top10CommentUser).map((key) => ({
                name: key,
                value: top10CommentUser[key],
              })),
            },
          ];
        } catch (error) {
          console.error('Error fetching card data:', error);
        }
      } else {
        this.cardData = CardData[this.source] || [];
      }
    },

    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData(page, this.pageSize, this.searchKeyword, this.tagText);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchData(this.currentPage, size, this.searchKeyword, this.tagText);
    },
    updatePageSize(size) {
      this.pageSize = size;
    },
    toggleComments(item) {
      item.showComments = !item.showComments;
    },
    formatDate(timestamp) {
      return format(new Date(timestamp * 1000), 'yyyy-MM-dd HH:mm:ss');
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollToBottom() {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    },
    updateScrollButtons() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      this.isAtTop = scrollTop === 0;
      this.isAtBottom = scrollTop + clientHeight >= scrollHeight - 1; // 确保计算精确
    },
    handleSearch() {
      this.currentPage = 1; // 搜索时重置页码为第一页
      this.fetchData(this.currentPage, this.pageSize, this.searchKeyword, this.tagText);
    },
    handleTagClick(tag) {
      this.tagText = tag.value;
      this.activeTag = tag.display; // 更新选中的标签
      this.currentPage = 1; // 点击标签时重置页码为第一页
      this.fetchData(this.currentPage, this.pageSize, this.searchKeyword, this.tagText);
    },
  },
  created() {
    this.fetchData(this.currentPage, this.pageSize);
    this.fetchCardData();
      /* 新增，获取卡片数据 */
  },
  mounted() {
    window.addEventListener('scroll', this.updateScrollButtons);
    this.updateScrollButtons();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateScrollButtons);
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
}
.card-list {
  flex: 1;
  margin-right: 20px;
  margin-left: 300px;
}
.sidebar {
  width: 300px;
  margin-right: 80px;
}
.search-container {
  margin-bottom: 20px;
}
.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.button-group .el-button {
  margin-bottom: 10px;
}
.button-group .el-button.active {
  background-color: #409EFF;
  color: white;
}
.el-card {
  margin-bottom: 20px;
  max-width: 1200px;
}
.header-content {
  display: flex;
  align-items: center;
}
.icon {
  margin-right: 5px;
}
.header-text {
  margin-right: 25px;
  color: #888;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}
.image {
  width: 60%;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: transform 0.3s ease; /* 添加过渡效果 */
}
.image:hover {
  transform: scale(1.2); /* 鼠标悬停时放大图片 */
}
.comment-button-container {
  margin-top: 10px;
}
.comments-container {
  margin-top: 10px;
  border-top: 1px solid #dcdcdc;
  padding-top: 10px;
}
.comment-item {
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 0;
}
.comment-item:last-child {
  border-bottom: none;
}
.card-item {
  padding-left: 10px; /* 添加内边距 */
  padding-bottom: 5px;
  margin-bottom: 2px; /* 添加一些底部间距 */
}
.scroll-to-top, .scroll-to-bottom {
  width: 40px;
  height: 40px;
  background-color: #409EFF;
  color: white;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 50px;
}
.scroll-to-top {
  bottom: 150px;
  right: 400px;
}
.scroll-to-bottom {
  bottom: 100px;
  right: 400px;
}
.disabled {
  background-color: #dcdcdc;
  cursor: not-allowed;
}
</style>