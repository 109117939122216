<template>
    <TianyaBaseComponent :source="2" />
  </template>
  
  <script>
  import TianyaBaseComponent from './TianyaBaseComponent.vue';
  
  export default {
    name: 'TianyaBuluo',
    components: {
      TianyaBaseComponent,
    },
  };
  </script>
  
  <style scoped>
  /* 如果有需要，可以添加特定于 TianyaBuluo 的样式 */
  </style>